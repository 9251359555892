import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/view',
    name: 'view',
    component: () =>
      import('../pages/view' /* webpackChunkName: "view" */),
    meta: {
      restricted: false
    },
  },
]

const router = new VueRouter({
  //mode: 'history', // Evita que me aparezca este numeral (#) en las rutas
  routes
})

export default router
